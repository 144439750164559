$ = require("jquery");

$(() => 
  $(".toggle-checker").change((e) => {
    var targetClass = "." + $(e.currentTarget).data('target');
    if($(e.currentTarget).is(':checked')) {
      $(targetClass).prop('checked', true);
    } else {
      $(targetClass).prop('checked', false);
    }

  })
);

$(() =>
  $(".clickable-row").click((e) => {
    targetElement = $(e.currentTarget).parent('tr')
    var targetURL = `${targetElement.data('row')}/${targetElement.data('href')}`;
    window.location.href = targetURL;
  })
);
